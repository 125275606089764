import React, { useEffect, useState } from 'react'
import { Button } from '../../../components/common'
import { AuthFlowWrapper } from '../../../components/views/layout'

import * as styles from './confirmation.module.scss'
import { Paths } from '../../../constants/structure'
import { getAbsolutePath } from '../../../common/utils/navigation'

const StarterConfirmationPage = () => {
   const [origin, setOrigin] = useState<string>('')

   useEffect(() => {
      const url = getAbsolutePath()
      // get origin on component mount
      setOrigin(url)
   }, [])

   return (
      <AuthFlowWrapper
         heading={`Thanks! Your Starter account is confirmed. Please click below to open the app and Sign In.`}
      >
         <div className={styles.buttonWrapper}>
            <Button
               variant="secondary"
               minWidth="100%"
               type="link"
               href={`${origin}${Paths.LOGIN}`}
            >
               Sign In
            </Button>
         </div>
      </AuthFlowWrapper>
   )
}
export default StarterConfirmationPage
